import {post} from './index'
import {post_yun} from "./index_yun"
import {get} from "./index";
import {put} from "./index";
import qs from "qs";

/**
 * 添加问卷
 * @param data
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function addContent(data){
    return post('/api/questionnaire',data)
}

/**
 * 识别病例
 * @param data
 * @returns {Promise<AxiosResponse<any> | void>}
 */
export function textRcognition(data){
    return post_yun('/api2/v1/app/textRcognition/info',data)
}
//病例识别
export function getCaseMessage(id){
    return get('/api/admin/case/'+id)
}

//修改性别
export function putSex(id,data){
    return put('/api/admin/patientByCaseId/'+id,data)
}

/**
 * 修改病例  通过
 * @param id
 * @param data  病例内容
 * @param isAudit   审批
 * @returns {Promise<* | void>}
 */
export function putCaseGo(id,data){
    return put('/api/admin/case/'+id,data)
}

/**
 * 修改病例  驳回
 * @param id  id
 * @param data   病例内容
 * @param reject_reason   驳回原因
 * @param isAudit   审批
 * @returns {Promise<* | void>}
 */
export function putSexPass(id,data){
    return put('/api/admin/case/'+id,data)
}

/**
 * 验证是否有资格填写部分问卷
 * @param memberId
 * @returns {Promise<* | void>}
 */
export function isMemberCertStatus(memberId){
    return get("/api/isMemberCertStatus/"+memberId)
}

/**
 * 通过id查看单个病例详情
 * @param id
 * @returns {Promise<* | void>}
 */
export function caseById(id){
    return get("/api/admin/case/"+id)
}

/**
 * 修改病例
 * @param id
 * @param data
 * @returns {Promise<* | void>}
 */
export function updateCase(id,data){
    return put('/api/admin/case/'+id,data)
}

/**
 * 查询病例17 住院号 是否有重复的值
 */
export function caseTemplateId17HospitalNumber(hospitalNumber,data){
    return get('/api/questionnaire/caseTemplateId17HospitalNumber/'+hospitalNumber,data)
}

export function caseBuried(data){
    return post('/api/admin/auth/auth_data_report',data)
}


/**
 添加选择病例模板
 2023.7.1
 李传刚
 **/

// export function get_caseModes(){
//     return get("/api/app/caseTemplates",)
// }

export function post_caseModes(data){
    return post("/case/wenjuan/member/template",qs.stringify(data))
}

/** 获取医生用户信息 **/

export function memberInfo(memberId){
    return get("/case/wenjuan/member",{memberId})
}
/** 修改医生用户信息 **/

export function put_memberInfo(data){
    return put("/case/wenjuan/member",qs.stringify(data))
}

//提交病例

export function post_case(data){
    return post("/case/wenjuan/member",data)
}
//修改病例问卷
export function put_case(data){
    return put("/case/wenjuan/member/zc",data)
}

//获取病例回显数据
export function getCaseInfo(id,memberId){
    return get("/case/wenjuan/member/zc",{id,memberId})
}

//上传图片
export function uploadImg(File){
    return post('/api/admin/common/update',File,{headers:{'Content-Type':'multipart/form-data'}})
}

//病例收集数量是否已满
export function getCaseNumber(data){
    return get('/case/wenjuan/number',data)
}
//病例收集是否开启
export function getCaseOpen(data){
    return get('/case/wenjuan/isOpen',data)
}