import axios from "axios";

const header="https://yun-new-admin-api.yunicu.com"
// const header="http://admin-dev-api.yunicu.com"
// const header="http://d349173w60.51vip.biz"
// const header="http://localhost:10086"
// const header="http://192.168.31.152:12468"

export function post(url,params){
    return axios.post(header+url,params)
        .then((res)=>{
            return res.data
        }).catch((e)=>{
            console.log(e)
        })
}

export function get(url,params){
    return axios.get(header+url,{
            params
        })
        .then((res)=>{
            return res.data
        }).catch((e)=>{
            console.log(e)
        })
}
export function put(url,params){
    return axios.put(header+url,params)
        .then((res)=>{
            return res.data
        }).catch((e)=>{
            console.log(e)
        })
}
